<template>
    <modal name="dont-leave-modal" class="dont-leave-modal" @before-open="beforeOpen"
           width="500" height="auto" :adaptive=true>
        <div class="modal-box">
            <div class="close" @click="close()">
                <img src="https://enklav-production.s3.amazonaws.com/uploads/image/x-20x20-white.svg">
            </div>
            <div class="m-header bold">
                <p>Hang tight!</p>
            </div>
            <div class="m-body">
                <p v-if="is_video_uploaded">Please Save the card before leaving to keep your work.</p>
                <div v-else>
                    <p>Your video is uploading now, so please don’t leave yet.</p>
                    <p>Once it’s processing, you’re welcome to hit “Save” and move on—just be sure to choose a thumbnail cover image{{ for_product ? ' or add a trailer' : ''  }}.</p>
                </div>
                <div class="actions">
                    <div class="_btn round-btn purple" @click="close()">Back to page</div>
                    <div class="_btn round-btn" @click="confirm_fn()">Leave</div>
                </div>
            </div>
        </div>
    </modal>
</template>
<script>
    import {mapDynamicFields} from "./helpers/mapDynamicFields";

    export default {
        props: ['for_product'],
        name: 'Modal_Dont_Leave',
        data () {
            return {
                spath: `Videos.uploads.main`,
                confirmFn: null,
            }
        },
        computed: {
            ...mapDynamicFields('spath', {
                leave_without_done_uploading: 'ui_state.leave_without_done_uploading',
                stay_on_form: 'ui_state.stay_on_form',
                leave_without_confirm: 'ui_state.leave_without_confirm',
                video: 'api_state',
            }),
            is_video_uploaded: function () {
                return ['UPLOADED', 'SUBMITTED', 'PROGRESSING', 'COMPLETE'].includes(this.video.status)
            },
        },
        methods: {
            close() {
                this.stay_on_form = true;
                this.$modal.hide('dont-leave-modal');
                setTimeout(() => {this.stay_on_form = false;}, 500)
            },
            confirm_fn() {
                this.leave_without_done_uploading = true;
                this.leave_without_confirm = true;
                this.close()
                this.confirmFn()
            },
            beforeOpen(event) {
                if (event && event.params && event.params.confirmFn) {
                    this.confirmFn = event.params.confirmFn
                }
            }
        },
    }
</script>

<style lang="scss" scoped>
    .dont-leave-modal {
        .modal-box {
            padding: 40px;
            p {
                margin-bottom: 20px;
            }
        }
        .close {
            margin: 0 !important;
            padding: 10px !important;
            position: absolute;
            right: 10px;
            top: 10px;
            img {
                width: 20px;
                filter: invert(1.5) sepia(1) saturate(5) hue-rotate(175deg);
            }
            &:focus {
                outline: none;
            }
        }
        .m-header {
            text-align: center;
        }
        .m-body {
            text-align: center;
            .actions {
                margin: 40px auto 20px;
                ._btn {
                    display: inline-block;
                    width: auto;
                    min-width: 140px;
                    margin: 0 10px;
                    @media (max-width: 450px) {
                        min-width: 120px;
                    }
                }
            }
        }
        .dont-show-again label {
            margin-bottom: 4px;
        }
    }
</style>
<template>
<div class="feed feed__widget">
    <div class="feed__label">
        Home Feed
    </div>
    <div class="follower-info">
        <a
            class="fs__12"
            href="/trending_feeds?tab=followers">
            <span class="text__bold">{{ followersCount }}</span> Followers
        </a>
        &nbsp; . &nbsp;
        <a
            class="fs__12"
            href="/trending_feeds?tab=followings">
            <span class="text__bold">{{ followingsCount }}</span> Following
        </a>
    </div>
    <div class="trending-title">
        <span
            class="pointer"
            @click="toggleList">Trending Frequencies
            <span>{{ isHide ? '▼' : '▲' }}</span>
        </span>
        <span>
            <img
                src="../../assets/imgs/refresh.svg"
                class="refresh-follow"
                @click="updateFrequenciesList">
        </span>
    </div>
    <div
        v-show="!isHide"
        class="feed__trendingList">
        <div
            v-for="item in trendingFrequencies"
            :key="item.user_id"
            class="feed__line clearfix">
            <a
                class="ava__s"
                :style="{backgroundImage: `url(${item.avatar})`}"
                :href="`/users/${item.user_id}/other_profile`" />
            <div class="feed__line__content">
                <a
                    :href="`/users/${item.user_id}/other_profile`">
                    <span class="text__bold color__secondary">{{ item.name }}</span>
                </a>
                <div class="fs__12">
                    <span class="text__bold">{{ item.followers }}</span> Followers
                </div>
            </div>

            <div v-if="item.is_following">
                <button
                    class="btn btn__round btn__bordered follow-confirmed"
                    @click="follow('unfollow', item.user_id)">
                    <!--            <i class="icon-follow-confirmed" />-->
                    <img src="@assets/imgs/follow-confirmed.svg">
                </button>
            </div>
            <div v-else>
                <button
                    class="btn btn__round"
                    @click="follow('follow', item.user_id)">
                    <i class="icon-follow">
                        <span class="path1" /><span class="path2" /><span class="path3" /><span class="path4" /><span class="path5" /><span class="path6" /><span class="path7" /><span class="path8" />
                    </i>
                </button>
            </div>
        </div>
    </div>
    <button
        v-show="!isHide"
        :disabled="!needUpdate"
        class="btn btn__round"
        @click="updateFeed">
        Refresh Feed
    </button>
</div>
</template>

<script>
import Post from '@models/Post'
    import {mapActions, mapGetters} from 'vuex';

    export default {
        name: 'HomeFeed',
        data() {
          return {
            needUpdate: false,
            isHide: false,
          }
        },
        computed: {
            ...mapGetters('trending_feeds', {
                trendingFrequencies: 'getTrendingFrequencies',
                followersCount: 'getFollowersCount',
                followingsCount: 'getFollowingsCount'
            })
        },
        async created() {
            await this.setFrequencies();
        },
        methods: {
            ...mapActions('trending_feeds', {
                setFrequencies: 'TRENDING_FREQUENCIES',
                followUser: 'FOLLOW',
                updateFollowStatus: 'UPDATE_FOLLOW_STATUS'
            }),
            async updateFeed() {
              await Post.dispatch('RESET_PAGINATION')
              await Post.create({data: {}})
              await Post.dispatch('fetch');
              this.needUpdate = false
            },
            toggleList() {
              this.isHide = !this.isHide
            },
            async updateFrequenciesList() {
              this.isHide = false
              await this.setFrequencies()
            },
            async follow(type, user_id) {
                this.needUpdate = true
                var is_following = type == 'follow' ? true : false
                var response = await this.followUser({user_id, type});
                if(response.status == 200){
                  await this.updateFollowStatus({user_id, is_following})
                }
            }
        }
    }
</script>

<style lang="scss" scoped>
  .refresh-follow {
    width: 20px;
    margin-left: 3rem;
    cursor: pointer;
  }
  .trending-title {
    margin-top: 2.5rem;
    font-weight: bold;
    .icon-arrow {
        font-size: 18px;
        margin-left: 1rem;
        margin-right: 1rem;
            position: relative;
    top: 3px;
    }
  }
  .follow-confirmed {
      &:hover {
          background: #e4e2f1;
          border-color: #e4e2f1;
      }
  }
</style>
